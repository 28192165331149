<template>
  <li class="list-item">
    <ul class="innerList">
      <li class="dateBlk">
        <div class="dateBlk-date">
          <span class="month" v-if="month">{{ month }} /</span>
          <span class="date">{{ date }}</span>
        </div>
        <span class="day">{{ dayName }}</span>
        <div v-if="timeTxt == '翌日AM'" v-on:click="changeTime('pm')">
          <btnToggle
            class="order-state__btn on"
            btnTxt="翌日AM"
            color="negative"
          />
        </div>
        <div v-if="timeTxt == '指定日PM'" v-on:click="changeTime('am')">
          <btnToggle
            class="order-state__btn on"
            btnTxt="指定日PM"
            color="primary"
          />
        </div>
      </li>
      <li class="vacuum">内 真空</li>
      <li
        class="innerList-item"
        v-for="(product, key) in products.slice(
          index * dataPerPage - dataPerPage,
          index * dataPerPage
        )"
        :key="product.id"
      >
        <div class="itemRow" :class="{ extra: extra }">
          <div class="itemRow-col">
            <div class="extraQuatity" v-if="extra">
              <!-- 追加発注の時は表示 -->
              <div class="extraQuatity-item">
                <span class="extraQuatity-item__original">
                  {{ product.quantity }}
                </span>
                <span
                  class="extraQuatity-item__extra"
                  :class="{
                    red:
                      (product.deadlineAddOrder ** date * product.id * 19) %
                        13 >
                        10 && extraPeriod - product.deadlineAddOrder >= 0
                  }"
                >
                  +{{ product.add_quantity }}
                </span>
                <span
                  class="label-deadline over"
                  v-if="
                    deadLineLabel && extraPeriod - product.deadlineAddOrder < 0
                  "
                >
                  受付終了
                </span>
                <span
                  class="label-deadline today"
                  v-if="
                    deadLineLabel && extraPeriod - product.deadlineAddOrder == 0
                  "
                >
                  本日締切
                </span>
              </div>
            </div>
            <span
              class="quantity"
              :class="{
                positive: product.quantity + product.add_quantity > 0
              }"
            >
              <span class="num">
                {{ product.quantity + product.add_quantity }}
              </span>
              <span class="unit" :class="{ set: product.unit == 'セット' }">{{
                product.unit
              }}</span>
            </span>
          </div>

          <div class="itemRow-col">
            <div class="extraQuatity" v-if="extra">
              <!-- 追加発注の時は表示 -->
              <div class="extraQuatity-item">
                <span class="extraQuatity-item__original">
                  {{ product.vacuumQuantity }}
                </span>
                <span
                  class="extraQuatity-item__extra"
                  :class="{
                    red:
                      (product.deadlineAddOrder ** date * product.id * 23) %
                        13 >
                        10 && extraPeriod - product.deadlineAddOrder >= 0
                  }"
                >
                  +{{ product.addVacuumQuantity }}
                </span>
                <span
                  class="label-deadline over"
                  v-if="deadLineLabel && vacuumPeriod <= 1"
                >
                  受付終了
                </span>
                <span
                  class="label-deadline today"
                  v-if="deadLineLabel && vacuumPeriod == 2"
                >
                  本日締切
                </span>
              </div>
            </div>
            <span
              class="vacuumQuantity"
              :class="{
                positive: product.vacuumQuantity + product.addVacuumQuantity > 0
              }"
            >
              <span class="num">
                {{ product.vacuumQuantity + product.addVacuumQuantity }}
              </span>
              <span class="unit" :class="{ set: product.unit == 'セット' }">{{
                product.unit
              }}</span>
            </span>
          </div>
        </div>
        <div v-if="edit" class="amount-btn">
          <div
            v-on:click="minus(product, key)"
            class="amount-btn__item minus"
          ></div>
          <div
            v-on:click="plus(product, key)"
            class="amount-btn__item plus"
          ></div>
          <div
            v-on:click="vacuumMinus(product)"
            class="amount-btn__item minus"
          ></div>
          <div
            v-on:click="vacuumPlus(product)"
            class="amount-btn__item plus"
          ></div>
        </div>
      </li>
    </ul>
  </li>
</template>

<script>
// import { mapMutations } from "vuex";
import btnToggle from "@/components/btn/btnToggle";
export default {
  name: "orderDetailItemPrint",
  components: {
    btnToggle
  },
  data: function() {
    return {
      timeTxt: "",
      timeClass: "",
      today: "",
      extraPeriod: "", //追加発注の期限
      vacuumPeriod: "", //真空の期限 発送日の前日
      transferDays: 0 //配達日数 //shopの都道府県でtransferのdaysを検索
    };
  },
  computed: {
    order: function() {
      return this.$store.state.iseyaOrder.data.order;
    },
    // products : function(){
    //   return this.$store.state.products
    // },
    todaysLock: function() {
      return this.$store.state.todaysLock;
    },
    deadLineLabel: function() {
      return this.$store.state.deadLineLabel;
    }
  },
  props: {
    year: Number,
    month: Number,
    date: Number,
    dayName: String,
    time: String,
    edit: Boolean,
    day: Number,
    orderNum: Number,
    extra: Boolean,
    item: Object,
    products: Array,
    dataPerPage: Number,
    index: Number,
    pagePerItems: Number
  },
  watch: {
    year(value) {
      this.year = value.map(() => false);
    },
    month(value) {
      this.month = value.map(() => false);
    },
    date(value) {
      this.date = value.map(() => false);
    }
  },
  mounted() {
    if (this.time == "pm") {
      this.timeTxt = "指定日PM";
      this.timeClass = "positive2";
    }
    if (this.time == "am") {
      this.timeTxt = "翌日AM";
      this.timeClass = "negative";
    }
    //今日の日付
    let getDate = new Date();
    let y = getDate.getFullYear();
    let m = getDate.getMonth();
    let d = getDate.getDate();
    this.today = new Date(y, m, d);
    //this.today = new Date(2022, 1, 6);

    let thisDay = new Date(this.year, this.month - 1, this.date); //到着日
    //追加発注の期限
    this.extraPeriod = (thisDay - this.today) / 86400000 - this.transferDays; //到着日 - 今日の日付 - 配達日数 なか日 - 配達日数（基準日）
    //真空の期限
    this.vacuumPeriod = (thisDay - this.today) / 86400000 - this.transferDays; //到着日 - 今日の日付 - 配達日数 なか日 - 配達日数（基準日）
  },
  methods: {
    // ...mapMutations(["plus", "minus", "vacuumPlus", "vacuumMinus"]),
    changeTime(time) {
      if (this.edit == true) {
        if (time == "am") {
          this.timeTxt = "翌日AM";
        } else {
          this.timeTxt = "指定日PM";
        }
      }
      this.item.thisDayTime = time;
    },
    quantityChange(product, index) {
      if (this.extra) {
        if (product.add_quantity < 0) {
          product.add_quantity = 0;
        }
      } else {
        if (product.quantity < 0) {
          product.quantity = 0;
        }
      }
      this.$store.commit("mathTotalData", { index: index });
    },
    plus(product, param) {
      if (this.extra && this.extraPeriod - product.deadlineAddOrder < 0) {
        return;
      }
      if (this.extra) {
        product.add_quantity++;
      } else {
        product.quantity++;
      }
      this.quantityChange(product, param.index);
    },
    minus(product, param) {
      if (this.extra) {
        product.add_quantity--;
      } else {
        product.quantity--;
      }
      this.quantityChange(product, param.index);
    },
    vacuumPlus(product) {
      if (this.extra) {
        product.addVacuumQuantity++;
        if (product.add_quantity < product.addVacuumQuantity) {
          product.addVacuumQuantity = product.add_quantity;
        }
      } else {
        product.vacuumQuantity++;
        if (product.quantity < product.vacuumQuantity) {
          product.vacuumQuantity = product.quantity;
        }
      }
    },
    vacuumMinus(product) {
      if (this.extra) {
        product.addVacuumQuantity--;
        if (product.addVacuumQuantity < 0) {
          product.addVacuumQuantity = 0;
        }
      } else {
        product.vacuumQuantity--;
        if (product.vacuumQuantity < 0) {
          product.vacuumQuantity = 0;
        }
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;
.list-item {
  width: calc(100% / 7);
  min-width: 160px;
  &.holiday,
  &.sunday {
    .month,
    .date,
    .day {
      color: variables.$sunday;
    }
  }
  &.saturday {
    .month,
    .date,
    .day {
      color: variables.$saturday;
    }
  }
}

.dateBlk {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100px;
  box-sizing: border-box;
  padding: 9px 0;
  border-bottom: solid 1px variables.$bg-gray-2;
  border-right: solid 1px variables.$bg-gray-2;
}

.dateBlk-date {
  display: flex;
  justify-content: center;
  align-items: center;
}

.day {
  margin-bottom: 18px;
}

.vacuum {
  height: 30px;
  color: variables.$scrollbar-back;
  @extend .f_body;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-right: solid 1px variables.$bg-gray-2;
  padding-right: 17px;
}

.innerList-item {
  box-sizing: border-box;
  border-right: solid 1px variables.$bg-gray-2;
  &:nth-of-type(odd) {
    background: variables.$bg-gray-1;
  }
}

.itemRow {
  display: flex;
  padding: 15px;
  justify-content: flex-end;
  flex-wrap: wrap;
  box-sizing: border-box;
  height: 60px;
  border-bottom: solid 1px variables.$bg-gray-2;
  &.extra {
    padding: 5px 0px;
  }
}

.itemRow-col {
  width: 50%;
  box-sizing: border-box;
  &:nth-of-type(1) {
    padding-left: 10px;
  }
  &:nth-of-type(2) {
    padding-right: 10px;
  }
}

.extraQuatity {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.extraQuatity-item {
  width: 75px;
  box-sizing: border-box;
  text-align: right;
  @extend .f_body;
  font-size: 1.2rem;
  position: relative;
}

.extraQuatity-item__original {
  color: variables.$scrollbar-back;
  margin-right: 5px;
}

.extraQuatity-item__extra {
  color: variables.$primary;
  &.red {
    color: variables.$negative;
  }
}

.label-deadline {
  position: absolute;
  top: calc(50% + 10px);
  left: 50%;
  transform: translate(-50%, -50%);

  font-size: 12px;
  font-weight: 700; /* bold */
  color: #fff;

  width: 57px;
  height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  &.over {
    background: variables.$txt-default;
  }
  &.today {
    background: variables.$negative;
  }
}

.quantity,
.vacuumQuantity {
  opacity: 0.2;
  transition: all 0.5s ease-in-out;
  &.positive {
    opacity: 1;
  }
}

.quantity {
  color: variables.$txt-default;
  width: 52px;
  //margin-right: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-left: auto;
}

.vacuumQuantity {
  color: variables.$scrollbar-back;
  width: 55px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-left: auto;
}

.num {
  font-size: 2rem;
  letter-spacing: 0;
  white-space: nowrap;
}

.unit {
  margin: 0 0 3px 5px;
  letter-spacing: 0;
  white-space: nowrap;
  &.set {
    font-size: 1rem;
  }
}

.amount-btn {
  display: flex;
  box-sizing: border-box;

  border-right: solid 1px variables.$bg-gray-2;
}
.amount-btn__item {
  width: 25%;
  height: 40px;
  background: linear-gradient(#f4f4f4 0%, #ededed 100%);
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  &:before {
    content: "";
    width: 12px;
    height: 1px;
    background: variables.$scrollbar-back;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.plus {
  &:after {
    content: "";
    width: 1px;
    height: 12px;
    background: variables.$scrollbar-back;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
